/* eslint-disable no-undef */
import React from "react"
import TemplatePageWhatsappPrecios from "@components/pageFunctionalities/pageWhatsappPrecio/template"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>WhatsApp Business API: Precios actualizados del 2025  [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/whatsapp-business-api/precios/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Conoce los precios actualizados del 2025 para WhatsApp Business API. Además, conoce cómo obtenerla sin costo alguno."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="WhatsApp Business API: Precios actualizados del 2025 "
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/whatsapp-business-api/precios/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta
        property="og:description"
        content="Conoce los precios actualizados del 2025 para WhatsApp Business API. Además, conoce cómo obtenerla sin costo alguno."
      />
    </Helmet>
    <TemplatePageWhatsappPrecios location={location} />
  </div>
)

export default IndexPage
